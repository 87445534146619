<template>
  <div>
    <el-form :inline="true">
      <!--信息审核 搜索区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:200px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="queryinfo.start"
          type="datetime"
          :placeholder="$t('qxz')"
          align="right"
          :picker-options="pickerOptions"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="queryinfo.end"
          type="datetime"
          :placeholder="$t('qxz')"
          align="right"
          :picker-options="pickerOptions"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-button icon="el-icon-search" circle @click="getlist"></el-button>
    </el-form>
    <!-- 列表区域 -->
    <el-table v-loading="loading" :data="infolist" border style="width: 100%;">
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column prop="code" :label="$t('mmb.danhao')"></el-table-column>
      <el-table-column prop="username" :label="$t('yhm')"></el-table-column>
      <el-table-column prop="trade" :label="$t('mmb.ycjsl')"></el-table-column>
      <el-table-column
        prop="trade_type"
        :label="$t('mmb.leixing')"
      ></el-table-column>
      <el-table-column
        prop="product_type"
        :label="$t('mmb.cplx')"
      ></el-table-column>
      <el-table-column prop="price" :label="$t('mmb.jydj')"></el-table-column>
      <el-table-column
        prop="number_max"
        :label="$t('mmb.zdjysl')"
      ></el-table-column>
      <el-table-column
        prop="number_min"
        :label="$t('mmb.zxjysl')"
      ></el-table-column>
      <el-table-column
        prop="number_total"
        :label="$t('mmb.jyzl')"
      ></el-table-column>
      <el-table-column prop="status" :label="$t('zhuangtai')">
        <template slot-scope="{ row }">
          <span v-if="row.status === '0'">{{ $t('mmb.dsh') }}</span>
          <span v-else-if="row.status === '1'">{{ $t('mmb.ysh') }}</span>
          <span v-else-if="row.status === '2'">{{ $t('mmb.jujue') }}</span>
          <span v-else-if="row.status === '3'">{{ $t('mmb.yjs') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createtime"
        :label="$t('cjsj')"
        :formatter="dateFormat"
      ></el-table-column>
      <el-table-column prop="remark" :label="$t('beizhu')"></el-table-column>
      <el-table-column :label="$t('caozuo')" min-width="120px">
        <template slot-scope="{ row }">
          <el-button
            v-if="row.status === '0'"
            @click="shenhe(row.id)"
            size="small"
            >{{ $t('xxsh') }}</el-button
          >
          <span v-else></span>
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 审核弹窗区域 -->
    <el-dialog
      :title="$t('xxsh')"
      center
      :visible="shenhevisible"
      :before-close="shenheclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form v-if="editform" :model="editform" label-width="140px">
        <el-form-item :label="$t('mmb.zdjysl')">
          <el-input-number
            v-model="editform.number_max"
            :disabled="true"
          ></el-input-number>
        </el-form-item>
        <el-form-item :label="$t('mmb.zxjysl')">
          <el-input-number
            v-model="editform.number_min"
            :disabled="true"
          ></el-input-number>
        </el-form-item>
        <el-form-item :label="$t('mmb.jjzl')">
          <el-input-number
            v-model="editform.number_total"
            :disabled="true"
          ></el-input-number>
        </el-form-item>
        <el-row style="display:flex">
          <el-form-item :label="$t('mmb.jjdj')">
            <el-input-number
              v-model="editform.price"
              :disabled="true"
            ></el-input-number>
          </el-form-item>
          <el-form-item :label="$t('mmb.jjdw')">
            <el-select v-model="editform.unit" disabled>
              <el-option
                :label="editform.unit"
                :value="editform.unit"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-form-item :label="$t('mmb.leixing')">
          <el-select v-model="editform.trade_type" disabled>
            <el-option
              :label="editform.trade_type === '1' ? '卖币' : '买币'"
              :value="editform.trade_type"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('mmb.cplx')">
          <el-select v-model="editform.product_type" disabled>
            <el-option
              :label="editform.product_type"
              :value="editform.product_type_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('mmb.sfwxnyh')">
          <el-radio-group v-model="editform.is_real">
            <el-radio :label="'1'" disabled>{{ $t('shi') }}</el-radio>
            <el-radio :label="'0'" disabled>{{ $t('fou') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('mmb.yjysl')">
          <el-input-number
            v-model="editform.trade"
            :disabled="true"
          ></el-input-number>
        </el-form-item>
        <el-form-item :label="$t('mmb.fkylxfs')">
          <el-select v-model="editform.payment.name" disabled>
            <el-option
              :label="editform.payment.name"
              :value="editform.payment.id"
            ></el-option>
          </el-select>
          <el-form-item :label="$t('mmb.lxdh')">
            <el-select v-model="editform.payment.phone" disabled>
              <el-option
                :label="editform.payment.phone"
                :value="editform.payment.phone"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('mmb.lxlx')">
            <el-select v-model="editform.payment_id" disabled>
              <el-option
                :label="editform.payment_id"
                :value="editform.payment_id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('mmb.lxxx')">
            <div v-if="editform.payment['contact_info']">
              <el-input
                v-for="item in JSON.parse(editform.payment['contact_info'])"
                :key="item.field"
                v-model="item.value"
                :disabled="true"
              >
                <template slot="prepend">{{ item.field }}</template>
              </el-input>
            </div>
          </el-form-item>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <div slot="footer" class="dialog-footer">
        <el-button @click="shenheclose">{{ $t('quxiao') }}</el-button>
        <el-button type="primary" @click="shenhesubmit(1)">{{
          $t('mmb.tongguo')
        }}</el-button>
        <el-button type="danger" @click="shenhesubmit(2)">{{
          $t('mmb.jujue')
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 编辑备注弹窗区域 -->
    <el-dialog
      :title="$t('bianji')"
      center
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item :label="$t('beizhu')">
          <el-input v-model="remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editclose">{{ $t('quxiao') }}</el-button>
        <el-button type="primary" @click="editsubmit">{{
          $t('tijiao')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 快捷时间
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      // 分页请求数据对象
      queryinfo: {
        query: '',
        start: '',
        end: '',
        page: 1
      },
      infolist: [],
      total: 0,
      loading: true,
      editdialogvisible: false,
      editid: '',
      // 统计信息
      order_count: 0,
      total_money: 0,
      profit: 0,
      shenhevisible: false,
      remark: '',
      editform: {
        payment: {
          name: '',
          phone: ''
        }
      }
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/trade/review', {
        params: this.queryinfo
      })
      this.infolist = data.data.list
      this.total = +data.data.count
      this.loading = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 格式化时间
    dateFormat(row) {
      const time = row.createtime
      const dt = new Date(parseInt(time) * 1000)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },
    // 打开审核弹窗
    async shenhe(id) {
      this.shenhevisible = true
      this.editid = id
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/trade/editreview/id/' + id)
      if (data) {
        if (data.code === 200) {
          this.editform = data.data
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 审核
    async shenhesubmit(status) {
      const { data } = await this.$http.post('/admin/trade/editreview', {
        id: this.editid,
        action: 'sh',
        content: status
      })
      if (data) {
        if (data.code === 200) {
          this.getlist()
          this.$message.success(data.msg)
          this.shenheclose()
        } else {
          this.$message.error(data.msg)
        }
      }
      this.editdialogvisible = false
    },
    // 关闭编辑弹窗
    shenheclose() {
      this.shenhevisible = false
    },
    // 打开编辑弹窗
    async edit(id) {
      this.editdialogvisible = true
      this.editid = id
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/trade/editreview/id/' + id)
      if (data) {
        if (data.code === 200) {
          this.remark = data.data.remark
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 编辑提交
    async editsubmit() {
      const { data } = await this.$http.post('/admin/trade/editreview', {
        id: this.editid,
        action: 'bj',
        content: this.remark
      })
      if (data) {
        if (data.code === 200) {
          this.getlist()
          this.$message.success(data.msg)
          this.editclose()
        } else {
          this.$message.error(data.msg)
        }
      }
      this.editdialogvisible = false
    },
    // 关闭编辑弹窗
    editclose() {
      this.editdialogvisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
.tongji {
  width: 100%;
  height: 56px;
  margin: 20px 0;
  .touzi {
    height: 56px;
    line-height: 56px;
    width: 200px;
    float: left;
    border-radius: 10px;
    background: #f5f7fa;
    margin-right: 20px;
    overflow: hidden;
    span:first-child {
      background: #2389f2;
      height: 56px;
      width: 80px;
      display: inline-block;
      color: #fff;
      text-align: center;
    }
    span:last-child {
      width: 120px;
      display: inline-block;
      text-align: center;
    }
  }
  .dingdan {
    height: 56px;
    line-height: 56px;
    width: 200px;
    float: left;
    border-radius: 10px;
    background: #f5f7fa;
    overflow: hidden;
    margin-right: 20px;
    span:first-child {
      background: #ffc913;
      height: 56px;
      width: 80px;
      display: inline-block;
      color: #fff;
      text-align: center;
    }
    span:last-child {
      width: 120px;
      display: inline-block;
      text-align: center;
    }
  }
  .yingkui {
    height: 56px;
    line-height: 56px;
    width: 200px;
    float: left;
    border-radius: 10px;
    background: #f5f7fa;
    overflow: hidden;
    span:first-child {
      background: #ff6213;
      height: 56px;
      width: 80px;
      display: inline-block;
      color: #fff;
      text-align: center;
    }
    span:last-child {
      width: 120px;
      display: inline-block;
      text-align: center;
    }
  }
}
</style>
